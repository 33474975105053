import { Injectable } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  banks: any = [
    // Public Banks
    'Bank Mandiri',
    'BNI',
    'BRI',
    'BTN',
    // Private Banks
    'BCA',
    'Bank Artha Graha',
    'Bank BTPN',
    'Bank CIMB Niaga',
    'Bank Commonwealth',
    'Bank Danamon',
    'Bank DBS',
    'Bank J Trust',
    'KB Bank',
    'Bank KEB Hana',
    'Bank Maspion',
    'Bank Maybank',
    'Bank Mega',
    'Bank MNC',
    'Bank OCBC NISP',
    'Panin Bank',
    'Bank Permata',
    'Bank QNB',
    'Bank Sinarmas',
    'Bank UOB',
    'Bank Victoria',
    // Syariah Banks
    'BSI',
    'BCA Syariah',
    'Bank Aladin Syariah',
    'KB Bank Syariah',
    'Maybank Syariah',
    'Bank Mega Syariah',
    'Bank Muamalat',
    'Bank Nano Syariah',
    'Panin Dubai Syariah',
    'Bank Victoria Syariah',
    // Regional Banks
    'Bank DKI',
    'Bank BJB',
    'Bank BPD DIY',
    'Bank Jateng',
    'Bank Jatim',
    'Bank BPD Bali',
    'Bank Sumut',
    'Bank Nagari',
    'Bank Riau Kepri',
    'Bank Sumsel Babel',
    'Bank Lampung',
    'Bank Jambi',
    'Bank Kalbar',
    'Bank Kalteng',
    'Bank Kaltim',
    'Bank Kalsel',
    'Bank Sulut',
    'Bank Sulsel',
    'Bank Sultra',
    'Bank BPD Sulteng',
    'Bank NTB',
    'Bank NTT',
    'Bank Maluku',
    'Bank Papua',
    // Digital Banks
    'Allo Bank',
    'Blu BCA Digital',
    'Bank Jago',
    'Bank Neo Commerce',
    'Bank Raya',
    'Seabank',
  ];

  constructor(
    private lightbox: Lightbox,
  ) {}

  isMobileView(): boolean {
    return window.innerWidth < 769;
  }

  calculateLuminance(hexColor: string): number {
    const rgb = parseInt(hexColor.substring(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    // Apply gamma correction
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma;
  }

  // In your color.service.ts
  lightenColor(color: string, percent: number): string {
    // Ensure the percent is between 0 and 100
    percent = Math.min(100, Math.max(0, percent));

    const num = parseInt(color.replace('#', ''), 16);
    const r = (num >> 16) + Math.round((255 - (num >> 16)) * (percent / 100));
    const g =
      ((num >> 8) & 0x00ff) +
      Math.round((255 - ((num >> 8) & 0x00ff)) * (percent / 100));
    const b =
      (num & 0x0000ff) + Math.round((255 - (num & 0x0000ff)) * (percent / 100));

    // Ensure values are within 0-255
    const newR = Math.min(255, Math.max(0, r));
    const newG = Math.min(255, Math.max(0, g));
    const newB = Math.min(255, Math.max(0, b));

    return (
      '#' + ((newR << 16) | (newG << 8) | newB).toString(16).padStart(6, '0')
    );
  }

  getContrastYIQ(hexcolor: string): string {
    const r = parseInt(hexcolor.slice(1, 3), 16);
    const g = parseInt(hexcolor.slice(3, 5), 16);
    const b = parseInt(hexcolor.slice(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white'; // Return 'black' for light colors, 'white' for dark colors
  }

  convertToTitleCase(input: string): string {
    return input
      .toLowerCase()
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  convertToSnakeCase(input: string): string {
    return input.toLowerCase().split('_').join('-');
  }

  bankList(): string[] {
    return this.banks;
  }

  openLightbox(imageUrl: string, caption: string) {
    const album = [
      {
        src: imageUrl,
        caption: caption,
        thumb: imageUrl
      }
    ];
    this.lightbox.open(album, 0);
  }
}
